<script>
import store from "@/store";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {onBeforeMount, ref} from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import useCoinMoneyLimitation from "@/views/pages/limitation/totalCoinLimitation/useCoinMoneyLimitation";
import {translatableText} from "@core/utils/utils";


export default {
  name: "TotalCoinMoneyLimitation",

  props: {
    totalCoinData: {
      type: Object,
      default: () => ({
        currencies: []
      })
    },
    currencies: {},
    type: {},
    subtitle: {},
    limit:{

    }
  },



  setup(props, {emit}) {

    const {
      currencyLimitation,
      limitSession,
      thereAreLimit,
      loadings,
      amounts,
      userData,
      getLimitsError,
      mapLimitationItem
    } = useCoinMoneyLimitation();
    const toast = useToast(ToastificationContent);
    const limitId = ref("");


    const buildLimit = (limits) => {

      limits.forEach((element) => {
        const userCurrencies = props.totalCoinData.currencies;
        const foundCurrency = userCurrencies.find(
            (currencyObject) => currencyObject === element.currency
        );
        if (foundCurrency) {
          const number = parseFloat(element.limit).toFixed(2);
          amounts.value[userCurrencies.indexOf(foundCurrency)] =
              number.toString();
        }

      });
    };


    const initAmounts = ()=> {

      props.totalCoinData.currencies.forEach((element,index)=>{
         amounts.value[index] = element+" "+"0.00";
      })
    }
    const notify = (message, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "DollarSignIcon",
          variant: variant,
        },
      });
    };

    const initComponent =  async () => {
      try {
        loadings.getLimitLoading = true;

         if (
             props.limit && Object.keys(props.limit).length>0
          ) {

            buildLimit(props.limit.limitations);
          } else {

           initAmounts();
         }
          getLimitsError.value = false;
      } catch (e) {

        notify(await translatableText({text: axiosErrorHandle(e)}), "danger");
        getLimitsError.value = true;
      } finally {
        loadings.getLimitLoading = false;
      }
    };

    const handleChangeLimits = () => {
        handleSetLimit();
    };

    const handleSetLimit = async () => {
      try {
        if (amounts.value && amounts.value.length > 0){
          loadings.getLimitLoading = true;
          const params = {
            userAddId:props.limit && props.limit._id ? props.limit.userAddId : userData.value?._id,
            whitelabelId:props.limit && props.limit._id ? props.limit.whitelabelId :props.totalCoinData._id,
            type:props.type,
            limitations: amounts.value.map(v=>mapLimitationItem(v)),
            method:"totalcoin"
          }
          await store.dispatch("payment/paymentLimitation", params);
          notify("Límites creados correctamente", "success");
          emit("reload-limits");
        }


         // await initComponent();
      } catch (error) {
         //handleResetConfig();
         notify(await translatableText({text: axiosErrorHandle(error)}), "danger");
       } finally {
         emit("close-main-modal");
         setTimeout(() => {
          loadings.getLimitLoading = false;
         }, 300);
       }
    };

    const resetAmount = ()=>amounts.value = [];


    const setDataFromComponent = (index, event) => {
      amounts.value[index] =
          event.split(" ")[0] +
          " " +
          parseFloat(event.split(" ")[1].replace(/,/g, ""));
      // checkThereAreActivesLimits();
    };


    const DeleteLimits = async ()=>{
      loadings.getLimitLoading = true;
      try {
        const params = {
          id:props.limit._id
        }
        await store.dispatch("payment/deletePaymentLimitation", params);
        notify(await translatableText({text: "Límites eliminados correctamente"}), "success");
        emit("reload-limits");


      }catch (error) {
         notify(await translatableText({text: axiosErrorHandle(error)}), "danger");
      } finally {
        setTimeout(() => {
          emit("close-main-modal");
          loadings.getLimitLoading = false;
        }, 300);
      }

    }

    onBeforeMount(()=>initComponent())








 /*   const handleSetLimitDuration = (limitDurationObject) => {
      currencyLimitation.durationType = limitDurationObject.valueType;
      currencyLimitation.duration = limitDurationObject.value;
    };*/

    return {
      limitSession,
      initComponent,
      setDataFromComponent,
      //handleSetLimitDuration,
      handleChangeLimits,
      currencyLimitation,
      thereAreLimit,
      loadings,
      amounts,
      getLimitsError,
      userData,
      mapLimitationItem,
      DeleteLimits




    }
  }
}
</script>

<template>
  <div>
    <b-container v-if="getLimitsError && !loadings.getLimitLoading">
      <b-card>
        <b-row>
          <b-col> No se pudieron cargar tus límites, inténtalo de nuevo </b-col>
          <b-col>
            <b-button
                variant="primary"
                :disabled="loadings.setLimitLoading"
                class="full-width"
                @click="initComponent()"
            >
              <feather-icon icon="RotateCcwIcon" class="mr-1" />
              Reintentar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-container>

    <b-container v-else-if="loadings.getLimitLoading">
      <b-row>
        <b-col
            md="6"
            v-for="(item, index) in totalCoinData.currencies"
            :key="index"
        >
          <b-skeleton animation="fade" class="mt-1" height="35px"></b-skeleton>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col class="d-flex justify-content-center">
          <b-skeleton
              v-for="(item, index) in 4"
              :key="index"
              animation="fade"
              width="80px"
              style="margin: 4px"
              height="30px"
          ></b-skeleton>
        </b-col>

        <b-col md="12">
          <b-skeleton animation="fade" class="mt-1" height="30px"></b-skeleton>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else>
      <h4 class="mb-1 d-flex justify-content-center">{{ subtitle }}</h4>
      <b-row v-if="Array.isArray(totalCoinData.currencies)">
        <b-col
            md="6"
            v-for="(currencyObject, index) of totalCoinData.currencies"
            :key="index"
        >
          <input
              spellcheck="false"
              :value="amounts[index]"
              :disabled="loadings.setLimitLoading"
              class="mb-1 form-control"
              @change="setDataFromComponent(index, $event.target.value)"
              v-money="{
            prefix: '' + currencyObject + ' ',
            masked: false,
            precision: 2,
          }"
          />
        </b-col>
      </b-row>

<!--      <b-row>
        <b-col>
          <b-button
              @click="handleSetLimitDuration(item)"
              size="sm"
              :disabled="loadings.setLimitLoading"
              style="margin: 5px"
              v-for="(item, index) in limitSession"
              :key="index"
              :variant="
            currencyLimitation.durationType === item.valueType &&
            currencyLimitation.duration === item.value
              ? 'primary'
              : 'secondary'
          "
          >
            {{ item.label }}
          </b-button>
        </b-col>
      </b-row>-->

      <b-row class="mt-2">
        <b-col>
          <b-button
              variant="primary"
              class="full-width"
              @click="handleChangeLimits()"
          >
            <b-spinner
                v-if="loadings.setLimitLoading"
                type="grow"
                label="Spinning"
                class="mr-1"
                small
            ></b-spinner>
            <feather-icon v-else icon="DollarSignIcon" class="mr-1" />
              {{$t('buttons.save') }}
          </b-button>

        </b-col>
      </b-row>
      <b-row class="mt-2 ">
        <b-col>
          <b-button
              variant="danger"
              class="full-width"
              :disabled="!limit"
              @click="DeleteLimits()"
          >
            <b-spinner
                v-if="loadings.setLimitLoading"
                type="grow"
                label="Spinning"
                class="mr-1"
                small
            ></b-spinner>

              {{$t('buttons.delete') }}
          </b-button>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped lang="scss">

</style>